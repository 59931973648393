<template>
  <div v-if="extracts.length > 0 || monitors.length > 0" class="ExtractTracker px-4 py-2" elevation="3">
    <monitor-job v-for="job of monitors" :key="job.bulkId" :monitor="job" @remove="removeMonitor" />

    <v-card class="Extract" v-for="extract in extracts" :key="extract.timestamp">
      <div class="Close" @click="remove(extract.timestamp)">
        <v-icon small color="mybb-grey-ligthen1">mdi-close</v-icon>
      </div>

      <div class="Icon">
        <v-progress-circular v-if="!extract.done" indeterminate size="40" class="mr-4" color="mybb-blue" />
        <v-icon v-else-if="extract.done && extract.success" size="40" color="mybb-success" class="mr-4">
          mdi-check
        </v-icon>
        <v-icon v-else-if="extract.done && !extract.success" size="40" color="mybb-error" class="mr-4">
          mdi-close-circle-outline
        </v-icon>
      </div>

      <div class="Labels">
        <mybb-text weight="bold" size="16" class="d-block mybb-grey-lighten1--text">{{ extract.label }}</mybb-text>
        <mybb-text v-if="!extract.hideStatus" class="Status mybb-grey-lighten1--text">
          {{ !extract.done ? statusPending(extract) : extract.success ? statusSuccess(extract) : statusError(extract) }}
        </mybb-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import FileDownload from 'js-file-download'

import MonitorJob from './MonitorJob'

/**
 * @typedef {{
 *  label: string,
 *  job: Promise,
 *  fileName: string,
 *  timestamp: number,
 *  hideStatus?: boolean,
 *  done?: boolean,
 *  success?: boolean
 * }} ExtractRequest
 */

export default {
  name: 'ExtractTracker',
  components: { MonitorJob },
  data() {
    return {
      extracts: [],
      monitors: []
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`extract-tracker.${key}`, params)
    },
    /**
     * @param {ExtractRequest} extract
     */
    addExtract(extract) {
      if (!extract) return

      if (!extract.label || !extract.job) {
        throw new Error('extract request must have a label and a job')
      }

      const index =
        this.extracts.push(Object.assign(extract, { done: false, success: null, timestamp: Date.now() })) - 1
      this.monitor(index)
    },
    addMonitoring(monitor) {
      if (!monitor) return

      if (!monitor.label || !monitor.bulkId) {
        throw new Error('monitor request must have a label and a bulkId')
      }

      this.monitors.push(monitor)
    },
    removeMonitor(bulkId) {
      this.monitors = this.monitors.filter(monitor => monitor.bulkId !== bulkId)
    },
    getFileName(extract) {
      if (extract.fileName) {
        return extract.fileName
      }

      const fileRegex = /attachment; filename="(.*)"$/.exec(extract.headers['content-disposition'])

      if (fileRegex) {
        return fileRegex[1]
      }

      const fileExtension = this.fileExtensionFromMimeType(extract.headers['content-type'])

      return `${extract.label}.${fileExtension}`
    },
    fileExtensionFromMimeType(mimeType) {
      switch (mimeType) {
        case 'application/pdf':
          return 'pdf'
        case 'application/zip':
          return 'zip'
        case 'image/png':
          return 'png'
        case 'image/jpeg':
        case 'image/jpg':
          return 'jpg'
        case 'image/svg+xml':
          return 'svg'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'docx'
        case 'application/msword':
          return 'doc'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'xlsx'
        case 'application/vnd.ms-excel':
          return 'xls'
        case 'text/plain':
          return 'txt'
        case 'text/csv':
          return 'csv'
        case 'text/html':
          return 'html'
        default:
          return 'unknown'
      }
    },
    async monitor(extractIndex) {
      if (extractIndex < 0) return

      const extract = this.extracts[extractIndex]

      if (!extract) return

      this.probe()

      try {
        const result = await extract.job
        if (result && result.data) {
          extract.data = result.data
          extract.headers = result.headers
        }

        extract.success = true

        if (extract.data instanceof Blob) {
          this.$nextTick(() => {
            FileDownload(extract.data, this.getFileName(extract))
          })
        }
      } catch (error) {
        console.error(error)
        extract.success = false
      } finally {
        extract.done = true
        this.unprobe()
      }

      this.extracts.splice(extractIndex, 1, extract)
    },
    remove(timestamp) {
      this.extracts = this.extracts.filter(extract => extract.timestamp !== timestamp)
    },
    probe() {
      if (window.onbeforeunload) return

      window.onbeforeunload = () => ''
    },
    unprobe() {
      const extractsRunning = this.extracts.some(extract => !extract.done)

      if (extractsRunning) return

      window.onbeforeunload = undefined
    },
    statusPending(extract) {
      return (extract.status && extract.status.pending) || this.t('status.pending')
    },
    statusSuccess(extract) {
      return (extract.status && extract.status.success) || this.t('status.success')
    },
    statusError(extract) {
      return (extract.status && extract.status.error) || this.t('status.error')
    }
  },
  mounted() {
    this.$bus.$on('extract', this.addExtract)
    this.$bus.$on('monitor', this.addMonitoring)
  }
}
</script>

<style lang="scss" scoped>
.ExtractTracker {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 100;

  .Extract {
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 8px 28px 8px 16px;
    margin-bottom: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 290px;
    height: 90px;

    .Close {
      position: absolute;
      cursor: pointer;
      top: 4px;
      right: 8px;
    }

    .Icon {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .Labels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 90%;
    }

    .Status {
      vertical-align: bottom;
    }
  }
}

.vertical-top {
  vertical-align: top;
}
</style>
