import gql from 'graphql-tag'

export const GET_MY_APPLICATIONS = gql`
  query getMyApplications {
    myApplications {
      description
      icon
      imageUrl
      title
      url
      businessUnitUuids
    }
  }
`
