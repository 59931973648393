<template>
  <v-card class="Monitor">
    <div class="Close" @click="$emit('remove', monitor.bulkId)">
      <v-icon small color="mybb-grey-ligthen1">mdi-close</v-icon>
    </div>

    <div class="flex-grow-1">
      <mybb-text weight="bold" size="16" class="d-block">{{ monitor.label }}</mybb-text>

      <div class="my-2">
        <completion-bar :data="completion" :total="$get(feedback, 'status.total', 0)" class="flex-grow-1" />
      </div>

      <mybb-text size="10" class="d-block text-center mybb-grey-lighten1--text">{{ monitor.bulkId }}</mybb-text>
    </div>
  </v-card>
</template>

<script>
import { FEEDBACK_STATUS } from '@/graphql/Monitoring'
import CompletionBar from '@/components/mybb/completion/CompletionBar'

export default {
  name: 'MonitorJob',
  components: { CompletionBar },
  props: {
    monitor: {
      type: Object,
      required: true,
      validator(prop) {
        return prop && prop.hasOwnProperty('label') && prop.hasOwnProperty('bulkId')
      }
    }
  },
  data() {
    return {
      intervalId: null,
      feedback: null
    }
  },
  apollo: {
    feedback: {
      query: FEEDBACK_STATUS,
      variables() {
        return { bulkId: this.monitor.bulkId }
      },
      update({ feedbackStatus }) {
        return feedbackStatus
      },
      result() {
        if (!this.hasPending) {
          this.$apollo.queries.feedback.skip = true
        }
      },
      pollInterval: 3000
    }
  },
  computed: {
    hasPending() {
      if (!this.feedback) return true

      return (
        this.feedback.status.pending > 0 ||
        (this.feedback.status.error > 0 && this.errorRate < 100) ||
        this.feedback.status.total <= 0
      )
    },
    successRate() {
      if (!this.feedback) return

      return (this.feedback.status.done / this.feedback.status.total) * 100
    },
    errorRate() {
      if (!this.feedback) return

      return (this.feedback.status.error / this.feedback.status.total) * 100
    },
    completion() {
      if (!this.feedback) return []

      return [
        {
          value: this.feedback.status.done,
          percentage: this.successRate,
          color: 'mybb-success'
        },
        {
          value: this.feedback.status.error,
          percentage: this.errorRate,
          color: 'mybb-error'
        },
        {
          value: this.feedback.status.total - (this.feedback.status.done + this.feedback.status.error),
          percentage: 100 - (this.successRate + this.errorRate),
          color: 'mybb-blue'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.ExtractTracker {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 100;

  .Monitor {
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 8px 28px 8px 16px;
    margin-bottom: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 290px;
    min-height: 90px;

    .Close {
      position: absolute;
      cursor: pointer;
      top: 4px;
      right: 8px;
    }

    .Icon {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .Labels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 90%;
    }

    .Status {
      vertical-align: bottom;
    }
  }
}

.vertical-top {
  vertical-align: top;
}
</style>
