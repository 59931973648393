<template>
  <v-container v-cy="$cy.dashboard.container">
    <v-row v-if="hasRight($const.right.manageDashboard)" justify="center">
      <v-btn :to="{ name: 'AdminManageDashboard' }" color="admin-primary" dark depressed>
        {{ $t('manage-dashboard.navigation-button') }}
      </v-btn>
    </v-row>
    <v-row>
      <template v-for="(app, i) in $store.state.applications">
        <v-col :key="i" cols="12" sm="6" lg="3">
          <AppCard :app="app" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import AppCard from '@/components/home/AppCard'

export default {
  name: 'HomeDashboard',
  components: { AppCard },
  computed: {
    ...mapGetters(['hasRight'])
  }
}
</script>
