<template>
  <v-dialog @click:outside="$emit('input', false)" max-width="700px" :value="value">
    <v-card class="Modal-Generate-Link" data-e2e="modal-send-mail-solicitation" :loading="loading.sendMail">
      <v-card-title class="headline mb-5">
        {{ $t('modalGenerateLink.headerModal', { fullName }) }}
      </v-card-title>
      <form @submit.prevent="sendEmail">
        <v-card-text>
          <div class="black--text mb-1 subtitle-1">{{ $t('modalGenerateLink.emailLabel') }}</div>
          <v-text-field
            v-model="email"
            v-validate="'required|email'"
            data-e2e="input-hcp-email"
            data-vv-name="email"
            :data-vv-as="$t`email`"
            :error-messages="errors.collect('email')"
            outlined
          />
          <div class="ModalSendMailSolicitation-mailContainer">
            <div v-if="loading.mailContent" class="d-flex justify-center align-center">
              <LoaderTab />
            </div>
            <p v-else v-html="solicitationMailContent" class="ModalSendMailSolicitation-mail" />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="error" data-e2e="button-cancel" @click="$emit('input', false)">{{
            $t('modalGenerateLink.cancelButton')
          }}</v-btn>
          <v-btn
            class="mr-4"
            color="success"
            data-e2e="button-confirm"
            :disabled="loading.sendMail"
            text
            type="submit"
            >{{ $t('modalGenerateLink.confirmButton') }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>

    <v-dialog v-model="validated" max-width="400" persistent>
      <v-card data-e2e="send-validated-container">
        <v-card-title
          class="headline d-block text-center"
          v-html="$t('modalGenerateLink.validateModal.title', { hcpName: fullName })"
        />
        <v-card-text class="mt-2 text-center">
          <v-icon size="80" color="success">mdi-check-circle-outline</v-icon>
        </v-card-text>
        <v-card-actions class="d-block text-center">
          <v-btn @click="closeModals" class="text-none" color="success" data-e2e="button-back" text>
            {{ $t('modalGenerateLink.backButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

import { SEND_CONGRESS_MAIL } from '@/graphql/Boolean/sendCongressMail'
import { GET_SOLICITATION_MAIL_CONTENT } from '@/graphql/SolicitationMailContent/getSolicitationMailContent'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'ModalSendMailSolicitation',
  components: { LoaderTab },
  $_veeValidate: {
    validator: 'new'
  },

  props: {
    emailAddress: {
      type: String,
      default: ''
    },
    congressUuid: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    gatheringUuid: {
      type: String,
      required: true
    },
    healthCareProfessionalUuid: {
      type: String,
      required: true
    },
    value: Boolean
  },

  data() {
    return {
      email: this.emailAddress,
      loading: {
        mailContent: false,
        sendMail: false
      },
      solicitationMailContent: null,
      validated: false
    }
  },

  apollo: {
    solicitationMailContent: {
      query: GET_SOLICITATION_MAIL_CONTENT,
      result() {
        this.loading.mailContent = false
      },
      variables() {
        this.loading.mailContent = true
        return { gatheringUuid: this.gatheringUuid, hcpUuid: this.healthCareProfessionalUuid, htmlTemplate: true }
      }
    }
  },

  methods: {
    ...mapMutations({
      setDialogEventList: 'SET_DIALOG_EVENT_LIST',
      setHcpList: 'SET_DIALOG_HCP_LIST',
      setModalMessage: 'SET_MODAL_MESSAGE'
    }),
    async sendEmail() {
      if (!(await this.$validator.validateAll())) return

      this.loading.sendMail = true
      const res = await this.$apollo.mutate({
        mutation: SEND_CONGRESS_MAIL,
        variables: {
          healthCareProfessionalUuid: this.healthCareProfessionalUuid,
          congressUuid: this.congressUuid,
          emailAddress: this.email
        }
      })
      this.loading.sendMail = false

      if (res.data.sendCongressMail) {
        this.validated = true
      }
    },
    closeModals() {
      this.$emit('input', false)
      this.setModalMessage({ display: false })
      this.setHcpList({ display: false })
      this.setDialogEventList({ display: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalSendMailSolicitation-mailContainer {
  max-height: 360px;
  overflow: auto;
}
.ModalSendMailSolicitation-mail {
  pointer-events: none;
}
</style>
