import gql from 'graphql-tag'

export const FEEDBACK_STATUS = gql`
  query feedbackStatus($bulkId: String!) {
    feedbackStatus(bulkId: $bulkId) {
      status {
        total
        done
        error
        pending
      }
      errors {
        entityUuid
        error
      }
    }
  }
`
