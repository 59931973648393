<template>
  <v-card tile data-e2e="hcp-list">
    <div>
      <div class="py-4 d-flex align-center justify-center text-center display-1">
        <span data-e2e="event-name">{{ modal.name }}</span>
        <span class="body-2 ml-5 grey--text" data-e2e="event-date">{{ formatedDate }}</span>
        <v-btn @click="closeModal" icon class="close-icon" data-e2e="button-close-moal"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </div>
    <v-divider class="my-2" />
    <v-container>
      <v-row justify="center">
        <v-col sm="8" md="6">
          <div class="label-input title">{{ $t`search-an-hcp` }}</div>
          <v-text-field v-model="hcpName" clearable data-e2e="input-search-hcp" outlined dense hide-details />
        </v-col>
        <v-col v-if="hasMslMeta" class="align-self-end pb-5">
          <v-switch v-model="bddVeeva" color="congrex-primary" data-e2e="button-search-on-veeva" dense hide-details>
            <template v-slot:label>
              <div class="labelSwitch">
                {{ $t('hcp-list.button-bdd-veeva') }}
              </div>
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-container>
    <div class="px-0 px-xl-10">
      <v-data-table
        class="HcpList-dataTable"
        :loading="loading"
        :loading-text="$t('charging-hcps')"
        :headers="headers"
        :items="hcps"
        :no-data-text="errorMessage || (alreadyLoaded ? $t`message-search-no-hcp` : '')"
        :options.sync="dataOptions"
        :server-items-length="veevaUsers.total"
        :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
        item-key="veevaId"
        :must-sort="!bddVeeva"
        :disable-sort="bddVeeva"
      >
        <template v-slot:[`item.lastName`]="{ value }">
          <div class="HcpList-dataTable-content">{{ value }}</div>
        </template>
        <template v-slot:[`item.firstName`]="{ value }">
          <div class="HcpList-dataTable-content">{{ value }}</div>
        </template>
        <template v-slot:[`item.title`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--160">{{ value }}</div>
        </template>
        <template v-slot:[`item.healthCareProfessional.specialty`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--160">
            {{ $t(`specialtyValues.${value}`) }}
          </div>
        </template>
        <template v-slot:[`item.city`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--160">{{ value }}</div>
        </template>
        <template v-slot:[`item.healthCareProfessional.zone.label`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--100">{{ value || '- -' }}</div>
        </template>
        <template v-slot:[`item.buLabel`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--80">{{ value }}</div>
        </template>
        <template v-slot:[`item.target`]="{ value }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--80">{{ value }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="HcpList-dataTable-content HcpList-dataTable-content--100 HcpList-dataTable-content--noEllipsis">
            <v-btn
              v-if="hasRight($const.right.collectSolicitation)"
              @click="createSolicitation(item)"
              class="mx-1 my-1 text-none"
              color="congrex-primary"
              data-e2e="button-create-solicitation"
              :dark="!disableCreateSolicitation(item)"
              depressed
              :disabled="disableCreateSolicitation(item)"
              fab
              x-small
            >
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="hasRight($const.right.collectSolicitation)"
              @click="openEmailModal(item)"
              icon
              color="primary"
              data-e2e="button-send-solicitation-by-mail"
              :disabled="disableCreateSolicitation(item)"
            >
              <v-icon dense>mdi-email-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <ModalSendMailSolicitation
      v-if="displayEmailModal"
      v-model="displayEmailModal"
      :congressUuid="modal.congressUuid"
      :fullName="fullNameHcpSelected"
      :gatheringUuid="modal.gatheringUuid"
      :healthCareProfessionalUuid="$get(itemSelected, 'healthCareProfessional.healthCareProfessionalUuid') || ''"
      :emailAddress="$get(itemSelected, 'healthCareProfessional.emailAddress') || itemSelected.email"
    />

    <modal v-model="bannedModal" :title="$t('banned-modal.title')">
      <mybb-text class="mt-3">{{ $t('banned-modal.text', { gatheringName: bannedGatheringName }) }}</mybb-text>
      <mybb-text weight="bold">{{ $t('banned-modal.highlight') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="bannedModal = false">{{ $t('banned-modal.close') }}</mybb-btn>
      </div>
    </modal>

    <modal v-model="alreadyInvitedModal" :title="$t('already-international-invited.title')">
      <mybb-text>{{ $t('already-international-invited.text') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="alreadyInvitedModal = false">
          {{ $t('already-international-invited.close') }}
        </mybb-btn>
      </div>
    </modal>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import moment from 'moment'

import ModalSendMailSolicitation from '@/components/congrex/ModalSendMailSolicitation'
import { GET_HCPS_FOR_SOLICITATION } from '@/graphql/VeevaUsers/GetVeevaUsers'
import { GET_GATHERING_EVENT_DATE_AND_PLACE } from '@/graphql/Gatherings/GetGathering'

import Modal from '../../mybb/Modal'

export default {
  name: 'HcpList',

  components: { ModalSendMailSolicitation, Modal },

  data() {
    return {
      alreadyLoaded: false,
      bddVeeva: false,
      dataOptions: {
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      displayEmailModal: false,
      errorMessage: '',
      hcpName: '',
      itemSelected: {},
      loading: false,
      modalParams: {},
      veevaUsers: {},
      bannedGatheringName: null,
      alreadyInvitedModal: false
    }
  },

  computed: {
    ...mapGetters(['hasRight', 'userBusinessUnitUuid']),
    ...mapState({ modal: state => state.modal.dialogHcpList }),
    bannedModal: {
      get() {
        return Boolean(this.bannedGatheringName)
      },
      set(value) {
        if (value) {
          this.bannedGatheringName = value
        } else {
          this.bannedGatheringName = null
        }
      }
    },
    fullNameHcpSelected() {
      if (!this.itemSelected.healthCareProfessional) return ''

      return `${this.itemSelected.healthCareProfessional.firstName} ${this.itemSelected.healthCareProfessional.lastName}`
    },
    headers() {
      return [
        { text: this.$t`lastName`, value: 'lastName', width: '200px' },
        { text: this.$t`firstName`, value: 'firstName', width: '199px' },
        { text: this.$t`title`, value: 'title', width: '160px' },
        { text: this.$t`specialty`, value: 'healthCareProfessional.specialty', width: '160px', sortable: false },
        { text: this.$t`city`, value: 'city', width: '160px' },
        { text: this.$t`zone`, value: 'healthCareProfessional.zone.label', width: '80px', sortable: false },
        { text: this.$t`bu`, value: 'buLabel', width: '80px', sortable: false },
        { text: this.$t`target`, value: 'target', width: '80px', sortable: false },
        { text: this.$t`actions`, value: 'actions', width: '100px', sortable: false }
      ]
    },
    hasMslMeta() {
      return Boolean(this.$store.state.myUser.mslMeta)
    },
    formatedDate() {
      return `${moment(this.modal.beginDate).format('DD')} - ${moment(this.modal.endDate).format('DD MMMM YYYY')}`
    },
    hcps() {
      if (!this.veevaUsers.results) return []

      return this.veevaUsers.results.map(user => ({
        attentendIntercontinentalCongressYears: this.$get(
          user.healthCareProfessional,
          'attentendIntercontinentalCongressYears',
          []
        ),
        buLabel: this.$get(user.healthCareProfessional, 'businessUnit')
          ? user.healthCareProfessional.businessUnit.label
          : '- -',
        city: user.cities[0],
        firstName: user.firstName,
        healthCareProfessional: user.healthCareProfessional,
        isSolicitationBannedFromGathering: user.healthCareProfessional.isSolicitationBannedFromGathering,
        lastName: user.lastName,
        target: this.$get(user.healthCareProfessional, 'businessUnit')
          ? user.healthCareProfessional.businessUnit &&
            user.healthCareProfessional[this.$const.targetBu[user.healthCareProfessional.businessUnit.label]]
          : '- -',
        title: this.$get(user.healthCareProfessional, 'title', '- -'),
        veevaId: user.veevaId
      }))
    }
  },

  apollo: {
    veevaUsers: {
      query: GET_HCPS_FOR_SOLICITATION,
      variables() {
        this.loading = true
        let variables = {
          businessUnitUuids: this.bddVeeva ? [] : this.$get(this.$store.state.myUser, 'abmMeta.businessUnitUuid', []),
          lastName: this.hcpName,
          limit: 10,
          mslTarget: true,
          zoneUuids: this.bddVeeva ? [] : this.$get(this.$store.state.myUser, 'mslMeta.zoneUuid', [])
        }

        if (this.dataOptions.page) {
          variables.offset = (this.dataOptions.page - 1) * this.dataOptions.itemsPerPage
          variables.limit = this.dataOptions.itemsPerPage
        }

        // Sorting
        if (variables.mslTarget && this.dataOptions.sortBy.length !== 0) {
          variables.sorts = [
            { field: this.dataOptions.sortBy[0], order: this.dataOptions.sortDesc[0] ? 'DESC' : 'ASC' }
          ]
        }

        return variables
      },
      debounce: 500,
      error(error) {
        console.error(error)
        this.loading = false
        this.errorMessage = JSON.stringify(error.message)
      },
      skip() {
        if (!this.modal.display) return true

        return !this.hasMslMeta && !this.hcpName && !this.abmMeta
      },
      result() {
        this.loading = false
        this.alreadyLoaded = true
        this.errorMessage = ''
      }
    },
    gathering: {
      query: GET_GATHERING_EVENT_DATE_AND_PLACE,
      variables() {
        return {
          gatheringUuid: this.modal.gatheringUuid
        }
      },
      skip() {
        return !this.modal || (this.modal && !this.modal.gatheringUuid)
      }
    }
  },

  methods: {
    ...mapMutations({
      setDialogHcpList: 'SET_DIALOG_HCP_LIST'
    }),
    closeModal() {
      this.setDialogHcpList({ display: false })
      this.hcpName = ''
      this.bddVeeva = false
    },
    createSolicitation(item) {
      if (this.$get(item, 'isSolicitationBannedFromGathering')) {
        this.bannedGatheringName = item.isSolicitationBannedFromGathering.name
        return
      }

      if (this.gathering) {
        const gatheringYear = new Date(this.gathering.beginDate).getFullYear()

        if (
          item.attentendIntercontinentalCongressYears.includes(gatheringYear) &&
          this.$get(this.gathering, 'congress.nationalityType') === this.$const.nationalityType.INTERNATIONAL
        ) {
          this.alreadyInvitedModal = true
          return
        }
      }

      this.$router.push({
        name: 'CreateSolicitation',
        query: {
          gatheringUuid: this.modal.gatheringUuid,
          veevaId: item.veevaId,
          businessUnitUuid: this.modal.businessUnitUuid || this.userBusinessUnitUuid
        }
      })

      this.$nextTick(this.closeModal)
    },
    disableCreateSolicitation(veevaUser) {
      const solicitations = this.$get(veevaUser, 'healthCareProfessional.solicitations')

      if (!solicitations) return false

      return solicitations.some(solicitation => solicitation.congressUuid === this.modal.congressUuid)
    },
    openEmailModal(item) {
      if (this.$get(item, 'isSolicitationBannedFromGathering')) {
        this.bannedGatheringName = item.isSolicitationBannedFromGathering.name
        return
      }

      if (this.gathering) {
        const gatheringYear = new Date(this.gathering.beginDate).getFullYear()

        if (item.attentendIntercontinentalCongressYears.includes(gatheringYear)) {
          this.alreadyInvitedModal = true
          return
        }
      }

      this.itemSelected = item
      this.displayEmailModal = true
    }
  },

  watch: {
    bddVeeva() {
      this.dataOptions.page = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.HcpList-dataTable {
  white-space: nowrap;
}

.HcpList-dataTable-content {
  display: inline-block;
  width: calc(200px - (2 * 16px));
  overflow: hidden;
  text-overflow: ellipsis;

  &--160 {
    width: calc(160px - (2 * 16px));
  }
  &--100 {
    width: calc(100px - (2 * 16px));
  }
  &--80 {
    width: calc(80px - (2 * 16px));
  }

  &--noEllipsis {
    text-overflow: unset;
  }
}

.close-icon {
  position: absolute;
  right: 20px;
}

.labelSwitch {
  width: 136px;
  font-size: 0.9em;
  line-height: 1em;
}
</style>
