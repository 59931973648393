<template>
  <v-app-bar
    app
    flat
    class="AppBar"
    height="50px"
    :dark="isDark"
    :color="appBarColor"
    :elevation="isMyBiogen ? 2 : 0"
    :class="{ 'congrex-separator': isCongrex }"
  >
    <v-app-bar-nav-icon @click="setDrawer(!drawer)" class="mr-3" color="congrex-primary" />

    <router-link v-if="isHome" key="Home" :to="{ name: 'Home' }">
      <img src="@/assets/logo-tooly.svg" />
    </router-link>
    <router-link v-else-if="isMyBiogen" class="d-flex header-logo-wrapper" key="CongrexBO" :to="{ name: 'CongrexBO' }">
      <img alt="Congrex Back-Office" class="header-logo" src="@/assets/congrex-bo-logo-v2.png" />
    </router-link>
    <router-link v-else-if="isCongrex" class="d-flex header-logo-wrapper" key="Congrex" :to="{ name: 'Congrex' }">
      <img alt="Congrex" class="header-logo" src="@/assets/congrex-logo-v2.png" />
    </router-link>
    <router-link
      v-else
      :to="{ path: `/${$route.path.split('/')[1]}` }"
      :class="`${contrastColor}--text text-uppercase`"
      key="Dashboard"
      :style="{ 'text-decoration': 'none' }"
    >
      <v-toolbar-title v-text="$route.meta.appName" class="font-weight-medium headline" />
    </router-link>

    <v-spacer />

    <div class="d-flex align-center">
      <span :class="`mr-2 ${contrastColor}--text`" v-html="$t('appBar.userMessage', { userName })" />
      <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn :color="contrastColor" depressed icon v-bind="attrs" v-on="on" v-cy="$cy.global.initials">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list nav>
          <v-list-item-group>
            <v-list-item @click="logout" v-cy="$cy.global.logout">{{ $t`logout` }}</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState(['drawer']),
    initialsUser() {
      return this.user.firstName[0].toUpperCase() + this.user.lastName[0].toUpperCase()
    },
    isMyBiogen() {
      return this.$route.meta.appName === 'Congrex BO'
    },
    isCongrex() {
      return this.$route.meta.appName === 'Congrex'
    },
    appBarColor() {
      if (this.isHome || this.isMyBiogen || this.isCongrex) return 'white'

      return `${this.$route.meta.theme || this.$route.meta.appName.toLowerCase()}-primary`
    },
    contrastColor() {
      if (this.isHome) return 'primary'
      if (this.isMyBiogen) return 'mybb-primary-darken1'
      if (this.isCongrex) return 'congrex-primary'
      return 'white'
    },
    isDark() {
      return !this.isHome && !this.isMyBiogen && !this.isCongrex
    },
    isHome() {
      return this.$route.meta.appName === 'Home'
    },
    user() {
      return this.$store.state.myUser
    },
    userName() {
      return `${this.user.firstName} ${this.user.lastName}`
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = 'Tooly'

        if (to.meta.appName === 'Congrex') {
          document.title = 'Biogen&Moi Staff'
        }

        if (to.meta.appName === 'Congrex BO') {
          document.title = 'Biogen&Moi Back'
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
      initState: 'RESET_STORE'
    }),
    logout() {
      this.initState()
      this.$cookies.remove('jwt')
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss">
.AppBar {
  .initials {
    font-size: 15px;
  }
}

.congrex-separator .v-toolbar__content {
  border-bottom: 1px solid var(--v-congrex-primary-base);
}

.header-logo-wrapper {
  max-height: 100%;
  padding: 5px 0;
}

.header-logo {
  width: auto;
  max-height: 90%;
  object-fit: contain;
  object-position: left;
}
</style>
