import gql from 'graphql-tag'

export const GET_SOLICITATION_MAIL_CONTENT = gql`
  query solicitationMailContent($gatheringUuid: GUID, $hcpUuid: GUID, $htmlTemplate: Boolean) {
    solicitationMailContent(
      gatheringUuid: $gatheringUuid
      healthCareProfessionalUuid: $hcpUuid
      htmlTemplate: $htmlTemplate
    )
  }
`
