import gql from 'graphql-tag'

export const GET_MY_USER = gql`
  query {
    myUser {
      abmMeta {
        businessUnitUuid
      }
      allRights
      email
      firstName
      isAuthenticated
      lastName
      mslMeta {
        businessUnitUuid
        zoneUuid
        zone {
          zoneUuid
          label
          businessUnit {
            businessUnitUuid
            label
          }
        }
      }
      userTypes
      userUuid
    }
  }
`
