<template>
  <v-snackbar v-model="show" :color="backgroundColor" :timeout="4000">
    <mybb-text :class="textColor">{{ text }}</mybb-text>
  </v-snackbar>
</template>

<script>
const DEFAULT_BACKGROUND_COLOR = 'mybb-primary-lighten1'
const DEFAULT_TEXT_COLOR = 'white--text'

export default {
  name: 'SnackBarGlobal',
  data() {
    return {
      show: false,
      text: '',
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
      textColor: DEFAULT_TEXT_COLOR
    }
  },
  methods: {
    showSnackBar(props) {
      if (!props) return

      this.backgroundColor = props.backgroundColor || DEFAULT_BACKGROUND_COLOR
      this.textColor = props.textColor ? `${props.textColor}--text` : DEFAULT_TEXT_COLOR
      this.text = props.text || ''

      this.show = true
    }
  },
  mounted() {
    this.$bus.$on('showSnackBarGlobal', this.showSnackBar)
  }
}
</script>
