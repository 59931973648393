<template>
  <v-app class="Index">
    <extract-tracker />
    <v-overlay v-if="!$route.meta.public && !allLoaded">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <template v-else>
      <v-snackbar top centered :value="isImpersonificated" :timeout="-1">
        {{ $t('mega-admin.toast') }}
        <v-btn color="pink" text @click="stopImpersonification">
          {{ $t('mega-admin.stop') }}
        </v-btn>
      </v-snackbar>
      <DashboardCoreAppBar v-if="!$route.meta.public" />
      <DashboardCoreDrawer v-if="!$route.meta.public" />
      <DashboardCoreView />

      <div v-if="$route.meta.appName === 'Congrex'" class="disclaimer">
        {{ $t`disclaimer-congrex` }}
      </div>
    </template>

    <v-dialog
      @keydown.esc="$store.commit('SET_DIALOG_HCP_LIST', { display: false })"
      :value="modal.dialogHcpList.display"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <HcpList />
    </v-dialog>
    <v-dialog
      @keydown.esc="$store.commit('SET_DIALOG_EVENT_LIST', { display: false })"
      :value="modal.dialogEventList.display"
      persistent
      no-click-animation
      fullscreen
      transition="dialog-bottom-transition"
    >
      <EventList
        :title="modal.dialogEventList.title"
        :subtitle="modal.dialogEventList.subtitle"
        :veevaId="modal.dialogEventList.veevaId"
      />
    </v-dialog>
    <v-dialog
      v-model="modal.modalMessage.display"
      @click:outside="clickOutsideModalMessage"
      :persistent="modal.modalMessage.persistent || true"
      content-class="modal-message"
    >
      <v-card>
        <div>{{ modal.modalMessage.text }}</div>
        <v-icon :color="modal.modalMessage.iconColor || 'success'" size="100">{{
          modal.modalMessage.icon || 'mdi-check-circle-outline'
        }}</v-icon>
        <v-btn
          v-if="modal.modalMessage.prevPage || modal.modalMessage.backLink"
          @click="linkModalMessage"
          outlined
          color="primary"
          class="mt-9 text-none"
          v-cy="$cy.congrex.modal.confirm"
        >
          {{ linkNameModalMessage }}
        </v-btn>
      </v-card>
    </v-dialog>

    <snack-bar-global />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import cssVars from 'css-vars-ponyfill'

import DashboardCoreAppBar from '@/components/core/AppBar'
import DashboardCoreDrawer from '@/components/core/Drawer'
import DashboardCoreView from '@/components/core/View'
import HcpList from '@/components/congrex/dialogs/HcpList'
import EventList from '@/components/congrex/dialogs/EventSearch'
import ExtractTracker from '@/components/extract/ExtractTracker'
import SnackBarGlobal from '@/components/mybb/ui/SnackBarGlobal'

import { GET_MY_USER } from '@/graphql/MyUser/GetMyUser'
import { GET_MY_APPLICATIONS } from '@/graphql/Application/GetMyApplications'
import { GET_ALL_BUS } from '@/graphql/BusinessUnit/GetBus'

export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar,
    DashboardCoreDrawer,
    DashboardCoreView,
    HcpList,
    EventList,
    ExtractTracker,
    SnackBarGlobal
  },

  computed: {
    ...mapState(['modal']),
    allLoaded() {
      if (!this.$store.state.myUser) return false

      return (
        Object.keys(this.$store.state.myUser).length > 0 &&
        this.$store.state.bus.length > 0 &&
        this.$store.state.applications.length > 0
      )
    },
    linkNameModalMessage() {
      if (this.modal.modalMessage.prevPage) {
        return this.$t`back-to-previous-page`
      }

      return this.$t('back-to-xx', { name: this.modal.modalMessage.backLink })
    },
    isImpersonificated() {
      const userUuid = this.$store.state.impersonificatedUser

      return Boolean(userUuid)
    }
  },

  apollo: {
    myUser: {
      query: GET_MY_USER,
      result({ data: { myUser } }) {
        this.$store.commit('SET_MY_USER', myUser)
      },
      skip() {
        return Boolean(this.$route.meta.public)
      }
    },
    myApplications: {
      query: GET_MY_APPLICATIONS,
      result({ data: { myApplications } }) {
        this.$store.commit('SET_MY_APPLICATIONS', myApplications)
      },
      skip() {
        return Boolean(this.$route.meta.public)
      }
    },
    businessUnits: {
      query: GET_ALL_BUS,
      result({ data: { businessUnits } }) {
        this.$store.commit('SET_BUS_AND_ZONES', businessUnits)
      },
      skip() {
        return (
          Boolean(this.$route.meta.public) ||
          (this.$store.state.bus && this.$store.state.bus.length > 0) ||
          !this.myUser
        )
      }
    }
  },

  mounted() {
    cssVars()
  },

  methods: {
    ...mapMutations({
      setModalMessage: 'SET_MODAL_MESSAGE'
    }),
    clickOutsideModalMessage() {
      if (this.modal.modalMessage.persistent === false) {
        this.setModalMessage({ display: false })
      }
    },
    linkModalMessage() {
      if (this.modal.modalMessage.prevPage) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: this.modal.modalMessage.backLink })
      }

      this.setModalMessage({ display: false })
    },
    stopImpersonification() {
      this.$store.dispatch('IMPERSONIFICATE_USER', null)

      this.$router.push({ name: 'Home' })

      this.$nextTick(() => {
        window.location.reload()
      })
    }
  },
  watch: {
    '$route.meta.public'(val, oldVal) {
      if (!val) {
        for (const query in this.$apollo.queries) {
          this.$apollo.queries[query].refetch()
        }
      }
    },
    $route(val, oldVal) {
      // Refresh the dashboard if the user could have changed the dashboard
      if (oldVal && oldVal.name === 'AdminManageDashboard') {
        this.$apollo.queries.myApplications.refresh()
      }
    }
  }
}
</script>

<style lang="scss">
.Index {
  .disclaimer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 17px 0;
    z-index: 3;
    background: #2b2b2b;
    color: white;
    font-size: 12px;
  }

  .modal-message {
    max-width: 400px;

    .v-card {
      padding: 25px 50px 50px;
      font-size: 19px;
      font-weight: 500;
      text-align: center;

      i {
        margin-top: 25px;
      }
    }
  }
}
</style>
