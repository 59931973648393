<template>
  <v-card class="AppCard" @click="navigate" :href="href" v-cy="$cy.dashboard.app(app.title)">
    <div class="ImageContainer">
      <v-img :src="imageUrl" max-height="100%" width="100%" contain />
    </div>
    <div class="px-4">
      <v-card-title class="AppCard-title py-0">
        <div>
          <span>{{ app.title }}</span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-if="app.description" v-on="on" class="AppCard-descriptionIcon" color="primary" right small>
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ app.description }}</span>
          </v-tooltip>
        </div>
      </v-card-title>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AppCard',

  props: {
    app: {
      type: Object,
      required: true
    }
  },

  computed: {
    isUrl() {
      return /^http(s)?:\/\//.test(this.app.url)
    },
    href() {
      if (process.env.NODE_ENV !== 'dev') return

      return this.target
    },
    target() {
      return this.isUrl ? this.app.url : this.$router.resolve({ name: this.app.url }).href
    },
    imageUrl() {
      return `${this.$baseUrl}${this.app.imageUrl}`
    }
  },

  methods: {
    navigate() {
      if (process.env.NODE_ENV !== 'dev') {
        return window.open(this.target, '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
.AppCard {
  border-radius: 10px !important;
  box-shadow: 0px 8px 13px #e0e0e0;

  .ImageContainer {
    height: 208px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #eaeaea;
  }

  .text-size {
    font-size: 13px;
  }
}

.AppCard-title {
  height: 80px;
  overflow: hidden;
}
.AppCard-description {
  height: 48px;
  overflow: hidden;
}

.AppCard-descriptionIcon {
  position: relative;
  top: -6px;
  right: 0;
}
</style>
