import gql from 'graphql-tag'

export const SEND_CONGRESS_MAIL = gql`
  mutation sendCongressMail($healthCareProfessionalUuid: GUID!, $congressUuid: GUID!, $emailAddress: EmailAddress) {
    sendCongressMail(
      healthCareProfessionalUuid: $healthCareProfessionalUuid
      congressUuid: $congressUuid
      emailAddress: $emailAddress
    )
  }
`
