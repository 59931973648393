<template>
  <v-navigation-drawer v-model="drawer" class="DashboardCoreDrawer" light app disable-resize-watcher temporary>
    <v-list-item>
      <router-link :to="{ name: 'Home' }">
        <v-list-item-content>
          <v-img src="@/assets/logo-tooly.svg" max-width="82" />
        </v-list-item-content>
      </router-link>
    </v-list-item>

    <v-list-item to="/" class="my-4"> <v-icon color="grey lighten-1" left>mdi-home</v-icon> Dashboard </v-list-item>

    <v-list v-if="showApplications">
      <v-subheader class="title-links text-uppercase">{{ $t`my-applications` }}</v-subheader>
      <v-list-item v-for="item in appLinks" :key="item.title" :to="{ name: item.to }" link exact dense>
        <v-list-item-icon class="mr-4">
          <img v-if="item.iconUrl" alt="icon" :src="`${$baseUrl}/images/application/${item.iconUrl}`" width="25px" />
          <v-icon v-else-if="item.icon" size="25px">mdi-{{ item.icon }}</v-icon>
          <div v-else class="icon-rounded grey">{{ item.title[0] }}</div>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-uppercase grey--text text--darken-3">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  data: () => ({}),

  computed: {
    ...mapState(['applications']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      }
    },
    appContext() {
      return this.$get(this.$route, 'meta.appName')
    },
    showApplications() {
      return ['Home', 'Congrex BO'].includes(this.appContext)
    },
    appLinks() {
      const routes = []

      switch (this.appContext) {
        case 'Congrex BO':
          routes.push(
            this.applications.find(app => app.to === 'CongrexBO'),
            this.applications.find(app => app.to === 'ListHCP')
          )
          routes[0] = JSON.parse(JSON.stringify(Object.assign({}, routes[0], { title: this.$t('eventList') })))
          break
      }

      return routes
    },
    myLinks() {
      return this.applications.filter(app => app.href)
    }
  }
}
</script>

<style lang="scss">
.DashboardCoreDrawer {
  .title-links {
    font-size: 13px !important;
    color: #a1a1a1 !important;
    font-weight: 500;
  }

  .icon-rounded {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 13px;
    font-weight: 500;
  }
}
</style>
