<template>
  <v-card tile class="EventSearch" v-cy="$cy.congrex.eventSearch.container">
    <div>
      <div>
        <div class="py-4 d-flex align-center justify-center text-center display-1">
          <span class="">{{ title }}</span>
          <span class="body-2 ml-5 grey--text">{{ subtitle }}</span>
          <v-btn @click="closeModal" icon class="close-icon" v-cy="$cy.congrex.eventSearch.close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <v-divider class="my-2" />
      <v-container>
        <p class="text-center body-2">{{ $t('event-search.caption') }}</p>
        <v-row justify="center">
          <v-col sm="8" md="6">
            <v-text-field
              v-model="eventName"
              :placeholder="$t('event-search.input-event-name-placeholder')"
              v-cy="$cy.congrex.eventSearch.search"
              clearable
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
      <div v-if="eventName" class="px-xl-10">
        <v-data-table
          :loading="loading"
          :loading-text="$t('charging-hcps')"
          :headers="headers"
          :items="events"
          :no-data-text="eventName ? $t('event-search.no-data') : ''"
          item-key="gatheringUuid"
          :items-per-page="1000"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="EventSearch-dataTable-actions">
              <v-btn
                v-if="$store.getters.hasRight($const.right.collectSolicitation) && item.showCreateSolicitation"
                @click="createSolicitation(item)"
                color="congrex-primary"
                :dark="!item.disableCreateSolicitation"
                depressed
                :disabled="item.disableCreateSolicitation"
                v-cy="$cy.congrex.eventSearch.createSolicitation(item.name)"
                fab
                x-small
              >
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="$store.getters.hasRight($const.right.collectSolicitation) && item.showCreateSolicitation"
                icon
                color="primary"
                class="ml-auto ml-md-5"
                :dark="!item.disableCreateSolicitation"
                :disabled="item.disableCreateSolicitation"
              >
                <v-icon @click="openEmailModal(item)" dense>mdi-email-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>

      <ModalSendMailSolicitation
        v-if="displayEmailModal"
        v-model="displayEmailModal"
        :congress-uuid="itemSelected.congressUuid"
        :email-address="modal.dialogEventList.emailAddress"
        :full-name="modal.dialogEventList.hcpFullName"
        :gatheringUuid="itemSelected.gatheringUuid"
        :health-care-professional-uuid="modal.dialogEventList.healthCareProfessionalUuid"
      />

      <modal v-model="alreadyInvitedModal" :title="$t('already-international-invited.title')">
        <mybb-text>{{ $t('already-international-invited.text') }}</mybb-text>

        <div class="text-center mt-10">
          <mybb-btn color="mybb-grey" @click="alreadyInvitedModal = false">
            {{ $t('already-international-invited.close') }}
          </mybb-btn>
        </div>
      </modal>

      <business-unit-picker-modal v-model="buPickerModal" :gathering-uuid="buPickerGatheringUuid" @submit="submit" />
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import { GET_GATHERINGS_FOR_EVENT_SEARCH } from '@/graphql/Gatherings/GetGatherings'
import { HCP_FOR_SOLICITATION_CREATION } from '@/graphql/Hcp/Hcp'
import ModalSendMailSolicitation from '@/components/congrex/ModalSendMailSolicitation'

import Modal from '../../mybb/Modal'
import BusinessUnitPickerModal from './BusinessUnitPickerModal'

export default {
  name: 'EventSearch',

  components: { ModalSendMailSolicitation, Modal, BusinessUnitPickerModal },

  props: {
    title: String,
    subtitle: String,
    veevaId: String
  },

  data() {
    return {
      displayEmailModal: false,
      eventName: '',
      gatherings: null,
      headers: [
        { text: this.$t('event-search.header.event-name'), value: 'name' },
        { text: this.$t('event-search.header.begin-date'), value: 'beginDate' },
        { text: this.$t('event-search.header.end-date'), value: 'endDate' },
        { text: this.$t('event-search.header.city'), value: 'city' },
        { text: this.$t('event-search.header.country'), value: 'country' },
        { text: this.$t('event-search.header.hcp-quota'), value: 'maximalQuota', align: 'center' },
        { text: this.$t('event-search.header.other-quota'), value: 'otherQuota', align: 'center' },
        { text: this.$t('event-search.header.actions'), value: 'actions' }
      ],
      itemSelected: {},
      loading: false,
      alreadyInvitedModal: false,
      buPickerGatheringUuid: null
    }
  },

  computed: {
    ...mapGetters(['userBusinessUnitUuid']),
    ...mapState(['modal']),
    buPickerModal: {
      get() {
        return Boolean(this.buPickerGatheringUuid)
      },
      set(value) {
        if (value) {
          this.buPickerGatheringUuid = value
        } else {
          this.buPickerGatheringUuid = null
        }
      }
    },
    events() {
      if (!this.gatherings || this.gatherings.length === 0) return []

      return this.gatherings.map(gathering => ({
        beginDate: this.$moment(gathering.beginDate).format('L'),
        city: gathering.city,
        congressUuid: gathering.congress.congressUuid,
        country: gathering.country,
        endDate: this.$moment(gathering.endDate).format('L'),
        gatheringUuid: gathering.gatheringUuid,
        maximalQuota: gathering.congress.maximalQuota,
        name: gathering.name,
        showCreateSolicitation: this.showCreateSolicitation(gathering),
        disableCreateSolicitation: this.shouldDisableCreateSolicitation(gathering),
        otherQuota: gathering.congress.otherQuota || '- -',
        gathering
      }))
    }
  },

  apollo: {
    gatherings: {
      query: GET_GATHERINGS_FOR_EVENT_SEARCH,
      skip() {
        return Boolean(!this.eventName)
      },
      variables() {
        this.loading = true
        return { eventName: this.eventName }
      },
      result() {
        this.loading = false
      },
      debounce: 500
    },
    healthCareProfessional: {
      query: HCP_FOR_SOLICITATION_CREATION,
      skip() {
        return !this.veevaId
      },
      variables() {
        return {
          veevaId: this.veevaId
        }
      },
      update({ healthCareProfessionalByExternalId }) {
        return healthCareProfessionalByExternalId
      }
    }
  },

  methods: {
    ...mapGetters(['hasRight']),
    ...mapMutations({
      setDialogHcpList: 'SET_DIALOG_EVENT_LIST'
    }),
    closeModal() {
      this.eventName = ''
      this.gatherings = null
      this.setDialogHcpList({ display: false })
    },
    shouldDisableCreateSolicitation(gathering) {
      if (!gathering.congress) return true

      if (this.userBusinessUnitUuid) {
        const gatheringBusinessUnit = gathering.gatheringBusinessUnits.find(
          gbu => gbu.businessUnitUuid === this.userBusinessUnitUuid
        )

        if (!gatheringBusinessUnit) return true

        const solicitationEndDate = new Date(gatheringBusinessUnit.solicitationEndDate)
        solicitationEndDate.setHours(23, 59, 59, 999)

        if (new Date(solicitationEndDate) - Date.now() < 0) {
          return true
        }
      } else {
        const gatheringBusinessUnit = gathering.gatheringBusinessUnits.find(
          gbu => new Date(gbu.solicitationEndDate) - Date.now() > 0
        )

        if (!gatheringBusinessUnit) {
          return true
        }
      }

      const hcpAlreadySolicited = gathering.congress.solicitations.some(
        solicitation => solicitation.healthCareProfessional.veevaId === this.veevaId
      )

      return hcpAlreadySolicited
    },
    showCreateSolicitation(gathering) {
      if (!gathering.congress) return false
      if (!this.hasRight(this.$const.right.collectSolicitation)) return false

      const { PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      if (![PUBLISHED, PUBLISHED_FRONT].includes(gathering.status)) return false

      if (this.userBusinessUnitUuid) {
        const businessUnitUuids = gathering.gatheringBusinessUnits.map(gbu => gbu.businessUnitUuid)

        return this.userBusinessUnitUuid && businessUnitUuids.includes(this.userBusinessUnitUuid)
      } else {
        return true
      }
    },
    openEmailModal(item) {
      const gatheringYear = new Date(item.gathering.beginDate).getFullYear()

      if (
        this.healthCareProfessional.attentendIntercontinentalCongressYears.includes(gatheringYear) &&
        this.$get(item.gathering, 'congress.nationalityType') === this.$const.nationalityType.INTERNATIONAL
      ) {
        this.alreadyInvitedModal = true
        return
      }

      this.itemSelected = item
      this.displayEmailModal = true
    },
    createSolicitation(item) {
      const gatheringYear = new Date(item.gathering.beginDate).getFullYear()

      if (
        this.healthCareProfessional.attentendIntercontinentalCongressYears.includes(gatheringYear) &&
        this.$get(item.gathering, 'congress.nationalityType') === this.$const.nationalityType.INTERNATIONAL
      ) {
        this.alreadyInvitedModal = true
        return
      }

      if (!this.userBusinessUnitUuid) {
        this.buPickerGatheringUuid = item.gatheringUuid
        return
      }

      this.submit(this.userBusinessUnitUuid, item.gatheringUuid)
    },
    submit(businessUnitUuid, gatheringUuid) {
      this.$router.push({
        name: 'CreateSolicitation',
        query: { gatheringUuid: gatheringUuid || this.buPickerGatheringUuid, veevaId: this.veevaId, businessUnitUuid }
      })
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
.EventSearch {
  .close-icon {
    position: absolute;
    right: 20px;
  }
}

.EventSearch-dataTable-actions {
  white-space: nowrap;
}
</style>
